import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export const onClientEntry = () => {
  setTimeout(() => {
    if (document.getElementById('loader-wrapper')) {
      document.getElementById('loader-wrapper').style.display = 'flex';
    }
  }, 0);
};

export const onInitialClientRender = () => {
  setTimeout(() => {
    if (document.getElementById('loader-wrapper')) {
      document.getElementById('loader-wrapper').style.display = 'flex';
    }
  }, 0);
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (document.getElementById('loader-wrapper')) {
      document.getElementById('loader-wrapper').style.display = 'none';
    }
  }, 3500);
};
