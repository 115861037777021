// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';
const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      button: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
      p: {
        fontFamily: `'Source Sans Pro', sans-serif`,
      },
    },
  },
  colors: {
    primary: '#101010',
    primaryWhite: '#FAFAFA',
    primaryGray: '#7C7C7C',
    primaryLightGray: '#6F6F6F',
    secondary: '#4597CB',
    secondaryLight: '#FFB94773',
  },
  fonts: {
    heading: `'Source Sans Pro', sans-serif`,
    body: `'Source Sans Pro', sans-serif`,
    button: `'Source Sans Pro' !important, sans-serif `,
  },
  button: {
    fontFamily: 'Source Sans Pro !important',
    color: 'red',
  },
});

export default theme;
